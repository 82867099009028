import { ReactNode, createContext, useContext } from "react";
import type { PublicEnv } from "../utils/public-env";

export const PublicEnvContext = createContext<PublicEnv | undefined>(undefined);

interface PublicEnvProviderProps {
  publicEnv?: PublicEnv;
  children: ReactNode;
}

export function PublicEnvProvider({
  publicEnv,
  children,
}: PublicEnvProviderProps) {
  return (
    <PublicEnvContext.Provider value={publicEnv}>
      {children}
    </PublicEnvContext.Provider>
  );
}

export function usePublicEnv() {
  const publicEnv = useContext(PublicEnvContext);

  if (publicEnv === undefined) {
    throw new Error(
      "Routes that use usePublicEnv must call the $publicEnv SSP",
    );
  }

  return publicEnv;
}
