import "@fontsource/source-sans-pro";
import "@fontsource/ubuntu";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { Suspense } from "react";
import { Header } from "../components/header";
import { useTokenRefresh } from "../hooks/use-token-refresh";
import theme from "../styles/theme";
import { PublicEnvProvider } from "../components/public-env-provider";
import { Sidebar } from "../components/sidebar";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../../mocks");
}

function App(appProps: AppProps) {
  const {
    Component,
    pageProps: { session, publicEnv, ...pageProps },
    router,
  } = appProps;

  return (
    <SessionProvider session={session}>
      <CssVarsProvider theme={theme}>
        <PublicEnvProvider publicEnv={publicEnv}>
          <CssBaseline />
          <Suspense
            fallback={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            {router.pathname.startsWith("/sign-in") ||
            router.pathname.startsWith("/signed-out") ? (
              <Component {...pageProps} />
            ) : (
              <AppLayout {...appProps} />
            )}
          </Suspense>
        </PublicEnvProvider>
      </CssVarsProvider>
    </SessionProvider>
  );
}

export default appWithTranslation(App);

function AppLayout(appProps: AppProps) {
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = appProps;

  useTokenRefresh();

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Sidebar />
      <Header />

      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress variant="soft" />
          </Box>
        }
      >
        <Box
          component="main"
          className="MainContent"
          sx={{
            pt: {
              xs: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          }}
        >
          <Component {...pageProps} />
        </Box>
      </Suspense>
    </Box>
  );
}
