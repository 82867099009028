import { FolderOpen, LogoutRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  GlobalStyles,
  IconButton,
  Sheet,
  Typography,
  listItemButtonClasses,
} from "@mui/joy";
import { closeSidebar } from "../utils/side-bar-classes";
import { ModeToggle } from "./dark-mode-toggle";
import { Navigation } from "./navigation";
import { useTranslation } from "next-i18next";
import { signOut, useSession } from "next-auth/react";

export function Sidebar() {
  const { t } = useTranslation();
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: {
          xs: 9999,
          md: 0,
        },
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: {
            xs: 9998,
            md: 0,
          },
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm">
          <FolderOpen />
        </IconButton>
        <Typography level="title-lg">{t("FileGateway")}</Typography>
        <ModeToggle />
      </Box>

      <Divider />

      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Navigation />
      </Box>
      <Profile />
    </Sheet>
  );
}

export function Profile() {
  const { t } = useTranslation();

  const { data: session } = useSession();

  const name = session?.user?.name;
  const email = session?.user?.email;

  const marketPartyName = session?.marktfacilitering?.marktpartij?.naam;
  const organisationalUnitName =
    session?.marktfacilitering?.marktpartij?.organisatieonderdeel?.[0].naam;

  return (
    <>
      <Divider />

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{name}</Typography>
          <Typography level="body-xs">{email}</Typography>
          <Typography level="body-xs">
            {organisationalUnitName ?? marketPartyName}
          </Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          aria-label={t('auth.Logout')}
          onClick={() => {
            void signOut({
              callbackUrl: "/signed-out",
            });
          }}
        >
          <LogoutRounded />
        </IconButton>
      </Box>
    </>
  );
}
