import { useSession, signIn } from "next-auth/react";
import { useEffect } from "react";

export function useTokenRefresh() {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.error === "RefreshAccessTokenError") {
      void signIn("openid-connect");
    }
  }, [session]);
}
